import React, { useEffect, useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import './PaymentSuccess.css';
import { getAppConfig, sendMailWithNodemailer } from "../../services/api";
import MySpinner from '../../components/MySpinner';
import { useNavigate } from 'react-router-dom';


const P_TefPayment = () => {

    const navigate = useNavigate();
    const [inProgress, setinProgress] = React.useState(true);
    const [appConfig, setAppConfig] = useState(null);


    const fetchAppConfig = async () => {

        try {

            setinProgress(true);

            const rsp = await getAppConfig();

            if (rsp.data.data) {
                setAppConfig(rsp.data.data);
            }

            setinProgress(false);

            sendMailWithNodemailer({
                from: 'services@exams-tcfcanada.com',
                to_email: `examstefcanada@gmail.com`,
                from_name: 'EXAMS TEF CANADA',
                subject: "POSSIBLE PAIEMENT PAR CARTE",
                text: `Un utilisateur vient d'arriver sur la page de redirection après un paiement par carte via Square. \n\nIl faut checker et activer son abonnement.`
            })

        } catch (error) {
            navigate('/not-found');
            setinProgress(false);
            return;
        }
    };

    useEffect(() => {
        fetchAppConfig()
    }, []);

    const formatDate = date => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(date).toLocaleDateString('fr-FR', options);
    };

    return (

        <div className="payment-success-container">
            <div className="payment-card">
                <div className="payment-header">
                    <img
                        src="../assets/img/adaptive-icon-tef.png"
                        alt="Traitement du Paiement"
                        className="payment-image"
                    />
                    <h1 className="payment-title">Opération terminée avec succès !</h1>
                </div>
                <p className="payment-description">
                    Votre demande a bien été reçue. Nous procédons actuellement au traitement et à la vérification de votre paiement. Cette vérification prend généralement quelques minutes, mais peut aller jusqu'à deux heures.
                </p>
                <p className="payment-note">
                    Si votre paiement a été effectué et que, passé ce délai, vous n'avez toujours pas accès à votre abonnement, veuillez nous contacter par WhatsApp, ou par email :
                </p>
                {
                    inProgress === false ? (
                        <a href={`https://wa.me/${appConfig?.customer_service_number || '+237652943007'}`} className="contact-button whatsapp-button">
                            <FaWhatsapp className="contact-icon" /> {appConfig?.customer_service_number || '+237652943007'}
                        </a>
                    ) : null
                }
                <a href="mailto:examstefcanada@gmail.com" className="contact-button email-button">
                    <MdEmail className="contact-icon" /> examstefcanada@gmail.com
                </a>

                <div className="payment-date">
                    <span>Date : {formatDate(Date.now())}</span>
                </div>
            </div>

            <MySpinner loading={inProgress} />

        </div>
    );
};

export default P_TefPayment;


